/* html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}


article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
} */
/* *{
  margin: 0;
  padding: 0;
} */


body {
  font-family: raleway;
  color: #fff;
  background: linear-gradient(45deg,  #7b28f0,  #1792b4,  #4ecda0, #ff9500fb);
  background-size: 400% 400%;
  -webkit-animation: Gradient 12s ease infinite;
  -moz-animation: Gradient 12s ease infinite;
  animation: Gradient 12s ease infinite;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#title {
  font-family: raleway;
  font-size: 60px;
  margin-top: 15%;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  
}

/* #home-body{
  background-color: red;
  margin-top: -10%;
} */

#home-title {
  font-family: raleway;
  font-size: 10vw;
  margin-top: 15%;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  text-shadow: 2px 2px 2px rgb(104, 100, 100);
}
#home-subtitle{
  font-family: raleway-light;
  font-size: 6vw;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  overflow: hidden;
  text-shadow: 2px 1px 2px rgb(102, 106, 100);
}

.container{

  margin-left: auto;
  margin-right: auto;
  font-family: "arial";
  font-size: 24px;
  display: grid;
  place-items: center;
  width: 53vw;
}

#home-subtitle {
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid;
  width: 0;
  animation: typing 2.5s steps(30, end) forwards, blinking 1s 4.5;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blinking {
  0% {border-right-color: transparent}
  50% {border-right-color: white}
  100% {border-right-color: transparent}
}

#subtitle{
  font-family: raleway-light;
  font-size: 40px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

}

#resume{
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

button{
  display: flex;
  background-color: #00000000;
  border: none;
}


.test {
  width: 100wh;
  height: 90vh;
  color: #fff;
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  -webkit-animation: Gradient 25s ease infinite;
  -moz-animation: Gradient 25s ease infinite;
  animation: Gradient 25s ease infinite;
  }

.paused {
  -webkit-animation-play-state: paused; /* Safari 4.0 - 8.0 */
  animation-play-state: paused;
}

@-webkit-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
}
}

@keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
}
}
body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --bg:  rgba(70, 70, 70, 0.13);
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms; 
}

/* .nav-bar-nav  ul {
  list-style: none;
  margin: 0;
  padding: 0;
} */

a {
  color: var(--text-color);
  text-decoration: none;
}

.navbar {
  height: var(--nav-size);
  border: none;
  padding: 0 1rem;
  
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}
.icon-button svg { 
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}
/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 60px;
  width: 300px;
  transform: translateX(-45%);
  background-color: #34c09fb4;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}


.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {

}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}



#resume-button-container{
  margin-left: auto;
  margin-right: auto;
  width: 16vw;
}

.glow-on-hover {
  font-family: raleway;
  margin-top: 4vw;
  justify-content: center;
  align-items: center;
  font-size: 2vw;
  width: 16vw;
  height: 4vw;
  border: none;
  outline: none;
  color: #fff;
  background: rgba(66, 49, 49, 0.2);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 5px 5px 10px #0000005e;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg,#7a48c0b0,  #3a4fbd,  #b6cd4e, #ff9500b9);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}


@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

@font-face {
  font-family: 'raleway';
  src: url(Raleway/static/Raleway-Medium.ttf);
}

@font-face {
  font-family: 'raleway-light';
  src: url(Raleway/static/Raleway-Light.ttf);
}

.MWIcon{
  position: relative;
  height: 70px;
  width: 70px;
  text-align: center;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding: 3px;
}


#home-button{
  border-radius: 200px;
  padding: 0px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 2%;
  left: 2%;
  margin-left: 0px;
  margin-top: 0px;
  
}
#home-button:hover{
  
   transform: scale(1.1); 
}



#sidebar{
  --height: 190px;
  height: var(--height);
  padding: 10px;
  padding-top: -280px;
  padding-bottom: 20px;
  background-color: #d4911436;
  position: absolute;
  margin-top: calc( var(--height) / 2 * -1);
  margin-bottom: auto;
  left: 0;
  top: 50%;
  box-shadow: 5px 5px 10px #0000005e;
  
}

 .sidebuttons {
  margin-top: 15px;
  padding: 4px;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  
} 


.sidebuttons:hover{
  background: linear-gradient(45deg, #ff9500fb, #4ecda0, #1792b4, #7b28f0);

  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
   outline: none;
   transform: scale(1.2); 
}

#resume-page{
  border-radius: 8px;
  margin-top: 60px;
  margin-left: 30px;
  margin-right: 30px;
  background-color: #6465655e;
  
}

#languages{
  top:-40px;
  padding-bottom: 10px;
}
.side-captions{
  font-weight: bold;
  text-decoration: underline;
  color: white;
}

.resume-body-captions{
  color: white;
  font-weight: 600;
  border-bottom:1px solid white;
  
  
}

#resume-sidebar-top ul{
  padding-left:  20px;
  list-style-type: square;
}

#resume-sidebar-top{
  padding-top: 30px;
  margin-top: -36px;
  left: 0;
  width: 220px;
  float: left;
}

#resume-sidebar-top p {
  color: white;
  padding: 30px 0 8px;
  position: relative;
  font-weight: 500;
}

#resume-sidebar-top p:before {
  content: "";
  position: absolute;
  left: 78px;
  bottom: 9px;
  width: 150px;
  height: 5px;
  transform: skew(-12deg) translateX(-50%);
  background: linear-gradient(to left, #c09ad4, #f89d25 100%);
  z-index: -1;
}

#resume-sidebar-bottom ul{
  padding-left:  20px;
  list-style-type: square;
}

#resume-sidebar-bottom{
  margin-top: 300px;
  margin-left: -220px;
  width: 260px;
  float: left;
}

#resume-body {
  text-align: left;
  margin-left: 226px;
  margin-right: auto;
  width: 80%;
}

#resume-work-title{
  color: white;
  padding: 30px 0 8px;
  position: relative;
  font-weight: 1100;
  margin-left: 38px;

}



#resume-work-title:before {
  content: "";
  position: absolute;
  left: 104px;
  bottom: 9px;
  width: 190px;
  height: 7px;
  transform: skew(-12deg) translateX(-50%);
  background: linear-gradient(to left, #c09ad4, #f89d25 100%);
  z-index: -1;
}

#resume-title{
  padding-top: 30px;
  margin-left: 34px;
  font-size: 60px;
  text-shadow: 2px 1px 1px rgb(104, 100, 100);
}

#resume-edu-title{
  margin-left: 38px;
  padding: 30px 0 8px;
  position: relative;
  font-weight: 1100;
}

#resume-edu-title:before {
  content: "";
  position: absolute;
  left: 164px;
  bottom: 9px;
  width: 320px;
  height: 7px;
  transform: skew(-12deg) translateX(-50%);
  background: linear-gradient(to left, #c09ad4, #f89d25 100%);
  z-index: -1;
}

#resume-courses-title{
  margin-left: 38px;
  padding: 30px 0 8px;
  position: relative;
  font-weight: 1100;
}

#resume-courses-title:before {
  content: "";
  position: absolute;
  left: 258px;
  bottom: 9px;
  width: 510px;
  height: 7px;
  transform: skew(-12deg) translateX(-50%);
  background: linear-gradient(to left, #c09ad4, #f89d25 100%);
  z-index: -1;
}

#resume-interests-title{
  margin-left: 38px;
  padding: 30px 0 8px;
  position: relative;
  font-weight: 1100;
}

#resume-interests-title:before {
  content: "";
  position: absolute;
  left: 164px;
  bottom: 9px;
  width: 320px;
  height: 7px;
  transform: skew(-12deg) translateX(-50%);
  background: linear-gradient(to left, #c09ad4, #f89d25 100%);
  z-index: -1;
}


#resume-body ul{
  font-weight: 500;
  list-style-type: square;
  margin-left: -22px;
}

#resume-work p {
  margin-top: -21px;
  margin-right: 30px;
  float: right;
}

#edu-time{
  margin-top: -20px;
  margin-right: 30px;
  float: right;
}
.subtitle{
  font-weight: 600;
  text-decoration: underline;
  color: white;
}

.clubs{
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: 40px;
}

.time {
  margin-top: -21px;
  margin-right: 30px;
  float: right;
}

#interests{
  margin-left: 128px;
  margin-top: -19px;
  padding-bottom: 200px;
}

#download-link{
  color: white;
  float: right;
  margin-top: 16px;
  margin-right: 2%;
}
#project-button{
  position: absolute;
  right: 36px;
  top: 30px;
  background-color: rgba(66, 49, 49, 0.2);
  border-radius: 20px;
  box-shadow: 5px 5px 10px #0000005e;
}
#project-link{
  font-size: 20px;
  font-weight: 500;
  color: white;
 }

#project-button:hover{
  border-radius: 2px;
  outline: none;
  border-radius: 30px;
  transform: scale(1.2); 
  box-shadow: 10px 10px 10px #0000005e;
}

.info-box{
  background-color: rgba(66, 49, 49, 0.2);
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 20px;
  margin-top: 80px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  
}

.info-box:hover{
  outline: none;
  transform: scale(1.005); 
  box-shadow: 5px 5px 5px 5px #0000005e;
}



#site{
  color: white;
  float:right;
  margin-top: -44px;
  margin-right: 130px;
  text-decoration: underline;
}
#site:hover{
  transform: scale(1.1); 
  text-shadow: 5px 5px 5px 5px #0000005e;
  /* box-shadow: 5px 5px 5px 5px #0000005e; */
}



#description{
  margin-top: 80px;
}

#source{
  color: white;
  float:right;
  margin-top: -44px;
  text-decoration: underline;
}
#source:hover{
  transform: scale(1.1); 
  text-shadow: 5px 5px 5px 5px #0000005e;
  /* box-shadow: 5px 5px 5px 5px #0000005e; */
}

#projects-title{
  margin-top: 60px;
  font-size: 60px;
  text-align: center;
}
.tools-container{
  display: flexbox;
  width: 900px;
  margin-top: -44px;
  margin-left: 104px;
  
}
.code-links{
  margin-top: 16px;
  margin-bottom: 0px;
  padding: 0;
}

.tools-container-webapps{
  display: flexbox;
  width: 700px;
  margin-top: -42px;
  margin-left: 104px;
  margin-bottom: 60px;
}


.tools-container-apps{
  display: flexbox;
  width: 700px;
  margin-top: -42px;
  margin-left: 104px;
  margin-bottom: 100px;
}

.tools{
  background-color: rgba(43, 46, 43, 0.387);
  /* margin-left: 40px; */
  border-radius: 5px;
  margin: 6px;
  padding: 4px;
  float:left;
  margin-top: 0px;
}

@media only screen and (max-width: 600px) {

  #home-title{
    margin-top: 100px;
  }

  #resume-button-container{
    margin-left: auto;
    margin-right: auto;
    width: 34vw;
  }

  .glow-on-hover{
    width: 34vw;
    height: 4vh;
    font-size: 3.4vw;
  }

  #resume-page{
    zoom: 120%;
    background-color: transparent;
  }

  #resume-body {
    width: 240%;
  
  }

  #download-link{
    margin-top: -68px;
    margin-right: -560px;
  }

  #edu-time{
    margin-top: -20px;
    margin-right: -20px;
    float: right;
    font-size: 12px;
  }

  .time{
    color: transparent;
  }
  #bottom {
    font-size: 10px;
  }

  #projects-title{
    width: 600px;
    margin-left:auto;
    margin-right: auto;
  }

  #projects{
    zoom: 60%;
    margin-left: 20px;
    margin-right: 20px;
    width: 200px;
  }

  .info-box{
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    /* width: 600px; */
    width: 280%;
  }
  .tools-container{
    margin-top: -60px;
    margin-left: 160px;
    
  }
  .tools{
    padding: 2px;
  }

  .tools-container h2{
    margin-top: 40px;
  }

  .tools-container-apps{
    margin-top: -60px;
    margin-left: 160px;
    width: 400px;
    margin-bottom: 110px;
  }

  .tools-container-webapps{
    margin-top: -60px;
    margin-left: 160px;
    width: 400px;
    margin-bottom: 100px;
  }
  
  
}

  @media only screen and (min-width: 1800px) {

   #home-body{
    margin-top: -5%;
   }

   
}